import React, { useState, useEffect } from 'react';
import axios from 'axios';
import NewTagForm from '../components/NewTagForm';
import { Box, Flex, Text, Button, SimpleGrid, Tag as ChakraTag, useDisclosure, VStack, Heading, Container, useColorModeValue, Skeleton } from '@chakra-ui/react';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';

const Tags = () => {
  const [tags, setTags] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchTags = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).token : '';
        const { data } = await axios.get('/api/tags', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setTags(data);
      } catch (error) {
        console.error(`Error fetching tags: ${error.message}\n${error.stack}`);
        setError('Failed to fetch tags');
      } finally {
        setLoading(false);
      }
    };

    fetchTags();
  }, []);

  const handleNewTagCreated = (newTag) => {
    setTags([...tags, newTag]);
    onClose();
  };

  const handleDeleteTag = async (tagId) => {
    try {
      const token = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).token : '';
      await axios.delete(`/api/tags/${tagId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setTags(tags.filter(tag => tag._id !== tagId));
    } catch (error) {
      console.error(`Error deleting tag: ${error.message}\n${error.stack}`);
    }
  };

  const bgColor = useColorModeValue('gray.50', 'gray.800');
  const cardBgColor = useColorModeValue('white', 'gray.700');

  if (loading) {
    return (
      <Box bg={bgColor} minHeight="100vh" py={10}>
        <Container maxW="container.xl">
          <VStack spacing={8} align="stretch">
            <Skeleton height="40px" width="150px" />
            
            <Box bg={cardBgColor} borderRadius="xl" p={6} boxShadow="lg">
              <SimpleGrid columns={[2, 3, 4, 5]} spacing={6}>
                {[...Array(10)].map((_, i) => (
                  <Skeleton
                    key={i}
                    height="40px"
                    borderRadius="full"
                  />
                ))}
              </SimpleGrid>
            </Box>
          </VStack>
        </Container>
      </Box>
    );
  }

  if (error) {
    return <Text color="red.500">{error}</Text>;
  }

  return (
    <Box bg={bgColor} minHeight="100vh" py={10}>
      <Container maxW="container.xl">
        <VStack spacing={8} align="stretch">
          <Flex justify="space-between" align="center">
            <Button
              onClick={onOpen}
              leftIcon={<AddIcon />}
              colorScheme="blue"
              size="lg"
              borderRadius="full"
              boxShadow="md"
            >
              New Tag
            </Button>
          </Flex>
          
          <Box bg={cardBgColor} borderRadius="xl" p={6} boxShadow="lg">
            <SimpleGrid columns={[2, 3, 4, 5]} spacing={6}>
              {tags.map(tag => (
                <ChakraTag
                  key={tag._id}
                  size="lg"
                  borderRadius="full"
                  variant="solid"
                  backgroundColor={tag.color}
                  color="white"
                  p={3}
                  justifyContent="space-between"
                  boxShadow="md"
                  _hover={{ transform: 'translateY(-2px)', boxShadow: 'lg' }}
                  transition="all 0.2s"
                >
                  <Text fontWeight="bold">{tag.name}</Text>
                  <DeleteIcon
                    cursor="pointer"
                    onClick={() => handleDeleteTag(tag._id)}
                    ml={2}
                    opacity={0.7}
                    _hover={{ opacity: 1 }}
                  />
                </ChakraTag>
              ))}
            </SimpleGrid>
          </Box>
        </VStack>
      </Container>
      
      <NewTagForm isOpen={isOpen} onClose={onClose} onTagCreated={handleNewTagCreated} />
    </Box>
  );
};

export default Tags;
