import { useEffect } from 'react';

class SSEManager {
  constructor() {
    this.eventSource = null;
    this.listeners = {};
    this.reconnectTimeout = null;
    this.backendUrl = process.env.REACT_APP_API_URL || 'http://157.245.245.212:8080';
  }

  connect() {
    if (!this.eventSource || this.eventSource.readyState === EventSource.CLOSED) {
      console.log('Attempting to connect to SSE at:', `${this.backendUrl}/api/sse`);
      this.eventSource = new EventSource(`${this.backendUrl}/api/sse`, { 
        withCredentials: true 
      });
      console.log('EventSource created:', this.eventSource);

      this.eventSource.onopen = (event) => {
        console.log('SSE connection opened:', event);
        clearTimeout(this.reconnectTimeout);
      };

      this.eventSource.onerror = this.handleError.bind(this);
      this.eventSource.onmessage = this.handleMessage.bind(this);
    }
  }

  handleMessage(event) {
    console.log('Raw SSE event received:', event);
    try {
      const data = JSON.parse(event.data);
      console.log('Parsed SSE data:', data);
      if (data.type && this.listeners[data.type]) {
        console.log(`Calling listeners for event type: ${data.type}`);
        this.listeners[data.type].forEach(callback => callback(data));
      } else if (!data.type) {
        console.warn('Received message without type:', data);
      } else {
        console.warn('No listeners for event type:', data.type);
      }
    } catch (error) {
      console.error('Error processing SSE message:', error);
      console.error('Raw event data:', event.data);
    }
  }

  handleError(error) {
    console.error('SSE error:', error);
    console.error('EventSource readyState:', this.eventSource.readyState);
    console.error('EventSource URL:', this.eventSource.url);
    
    if (this.eventSource.readyState === EventSource.CLOSED) {
      this.eventSource.close();
      clearTimeout(this.reconnectTimeout);
      this.reconnectTimeout = setTimeout(() => this.connect(), 5000);
    }
  }

  disconnect() {
    if (this.eventSource) {
      this.eventSource.close();
      this.eventSource = null;
    }
  }

  addListener(eventType, callback) {
    if (!this.listeners[eventType]) {
      this.listeners[eventType] = [];
    }
    this.listeners[eventType].push(callback);
    console.log(`Added listener for event type: ${eventType}`);
    console.log('Current listeners:', this.listeners);
  }

  removeListener(eventType, callback) {
    if (this.listeners[eventType]) {
      this.listeners[eventType] = this.listeners[eventType].filter(cb => cb !== callback);
      console.log(`Removed listener for event type: ${eventType}`);
      console.log('Current listeners:', this.listeners);
    }
  }
}

const sseManager = new SSEManager();

export const useSSE = (eventType, callback) => {
  useEffect(() => {
    sseManager.addListener(eventType, callback);
    return () => {
      sseManager.removeListener(eventType, callback);
    };
  }, [eventType, callback]);
};

export default sseManager;
