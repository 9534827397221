import React from 'react';
import axios from 'axios';
import { Box, Button, Table, Thead, Tbody, Tr, Th, Td, Text, Badge, Flex, Icon, useColorModeValue, Center, Skeleton, SkeletonText } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { FaDownload, FaFile } from 'react-icons/fa';

const FileList = ({ files, isLoading }) => {
  const handleDownload = async (fileId, fileName) => {
    try {
      const token = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).token : '';
      const response = await axios.get(`/api/uploads/download/${fileId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      toast.error('Failed to download file');
      console.error(`Error downloading file: ${error.message}\n${error.stack}`);
    }
  };

  const bg = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');

  const SkeletonRow = () => (
    <Tr>
      <Td><Skeleton height="20px" width="200px" /></Td>
      <Td><Skeleton height="20px" width="150px" /></Td>
      <Td><Skeleton height="20px" width="50px" /></Td>
      <Td><Skeleton height="20px" width="50px" /></Td>
      <Td><Skeleton height="30px" width="100px" /></Td>
    </Tr>
  );

  return (
    <Box p={4} bg={bg} boxShadow="md" borderRadius="lg" borderWidth="1px" borderColor={borderColor}>
      {isLoading ? (
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th><SkeletonText noOfLines={1} width="100px" /></Th>
              <Th><SkeletonText noOfLines={1} width="100px" /></Th>
              <Th><SkeletonText noOfLines={1} width="100px" /></Th>
              <Th><SkeletonText noOfLines={1} width="100px" /></Th>
              <Th><SkeletonText noOfLines={1} width="100px" /></Th>
            </Tr>
          </Thead>
          <Tbody>
            {[...Array(5)].map((_, index) => (
              <SkeletonRow key={index} />
            ))}
          </Tbody>
        </Table>
      ) : files.length === 0 ? (
        <Flex align="center" justify="center" height="200px">
          <Text fontSize="lg" color="gray.500">No files uploaded yet.</Text>
        </Flex>
      ) : (
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>File Name</Th>
              <Th>Upload Date</Th>
              <Th textAlign="center">Valid Count</Th>
              <Th textAlign="center">Duplicate Count</Th>
              <Th textAlign="center">Invalid Number Count</Th>
              <Th textAlign="center">Total Uploaded</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {files.map(file => (
              <Tr key={file._id}>
                <Td>
                  <Flex align="center">
                    <Icon as={FaFile} mr={2} />
                    <Text fontWeight="medium">{file.fileName}</Text>
                  </Flex>
                </Td>
                <Td>{new Date(file.createdAt).toLocaleString()}</Td>
                <Td>
                  <Center>
                    <Badge colorScheme="green" borderRadius="full" px="2">
                      {file.validCount}
                    </Badge>
                  </Center>
                </Td>
                <Td>
                  <Center>
                    <Badge colorScheme={file.duplicateCount > 0 ? "yellow" : "green"} borderRadius="full" px="2">
                      {file.duplicateCount}
                    </Badge>
                  </Center>
                </Td>
                <Td>
                  <Center>
                    <Badge colorScheme={file.invalidNumberCount > 0 ? "red" : "green"} borderRadius="full" px="2">
                      {file.invalidNumberCount}
                    </Badge>
                  </Center>
                </Td>
                <Td>
                  <Center>
                    <Badge colorScheme="blue" borderRadius="full" px="2">
                      {file.totalUploadedCount}
                    </Badge>
                  </Center>
                </Td>
                <Td>
                  <Button
                    colorScheme="blue"
                    size="sm"
                    onClick={() => handleDownload(file._id, file.fileName)}
                    leftIcon={<FaDownload />}
                  >
                    Download
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </Box>
  );
};

export default FileList;
