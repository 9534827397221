import React, { useState, useEffect } from 'react';
import OpportunityList from '../components/OpportunityList';
import { Box, Skeleton, Stack } from '@chakra-ui/react';

const Opportunities = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate loading time or fetch initial data
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return (
      <Box p={4}>
        <Stack spacing={4}>
          {[...Array(3)].map((_, i) => (
            <Box key={i} borderWidth="1px" borderRadius="xl" p={4}>
              <Skeleton height="40px" mb={4} />
              <Stack spacing={2}>
                <Skeleton height="20px" />
                <Skeleton height="20px" />
                <Skeleton height="20px" />
              </Stack>
            </Box>
          ))}
        </Stack>
      </Box>
    );
  }

  return (
    <Box p={4} boxShadow="md" borderRadius="md">
      <OpportunityList />
    </Box>
  );
};

export default Opportunities;
