import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { fetchWorkflows } from '../utils/api';
import FileList from '../components/FileList';
import UploadModal from '../components/UploadModal';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Box, Button, Flex } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import DragAndDropFileUpload from '../components/DragAndDropFileUpload';
import { handleFileChange as handleFileChangeUtil } from '../utils/fileUtils';
import { v4 as uuidv4 } from 'uuid';

export const fieldMappings = {
  'First Name': 'firstName',
  'Last Name': 'lastName',
  'Phone': 'phone',
  'Email': 'email',
  'Address': 'address',
  'City': 'city',
  'State': 'state',
  'Occupation': 'occupation',
  'Tags': 'tags'
};

const Uploads = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [headers, setHeaders] = useState([]);
  const [mapping, setMapping] = useState({});
  const [workflows, setWorkflows] = useState([]);
  const [selectedWorkflow, setSelectedWorkflow] = useState('');
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [fileListData, setFileListData] = useState({ loading: true, files: [] });
  const [refreshFileList, setRefreshFileList] = useState(0);
  const [fileKey, setFileKey] = useState(uuidv4());
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const handleFileChange = (newFile) => {
    handleFileChangeUtil(setFile, setHeaders)(newFile);
    setFileKey(uuidv4());
  };

  const handleNextClick = () => {
    if (file) {
      setIsModalOpen(true);
    } else {
      toast.error("Please select a file first.");
    }
  };

  const handleMappingChange = (e) => {
    const { name, value } = e.target;
    setMapping({
      ...mapping,
      [name]: value,
    });
  };

  const handleWorkflowChange = (e) => {
    setSelectedWorkflow(e.target.value);
  };

  const handleTagChange = (newTags) => {
    setSelectedTags(newTags);
  };

  const handleSubmit = async (keepDuplicates) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('workflowId', selectedWorkflow);
    formData.append('mapping', JSON.stringify(Object.keys(mapping).reduce((acc, displayName) => {
      acc[fieldMappings[displayName]] = mapping[displayName] || '';
      return acc;
    }, {})));
    formData.append('tags', JSON.stringify(selectedTags));
    formData.append('keepDuplicates', keepDuplicates);

    try {
      const token = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).token : '';
      await axios.post('/api/uploads', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      toast.success('Contacts uploaded successfully');
      setIsModalOpen(false);
      setActiveTab(0);
      setRefreshFileList(prev => prev + 1);
    } catch (error) {
      if (error.response && error.response.data.message) {
        toast.error(`Failed to upload contacts: ${error.response.data.message}`);
      } else {
        toast.error('Failed to upload contacts. Please try again.');
      }
      console.error(`Error uploading contacts: ${error.message}\n${error.stack}`);
    }
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const token = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).token : '';
        const config = {
          headers: { Authorization: `Bearer ${token}` }
        };

        const [workflowsData, tagsData] = await Promise.all([
          axios.get('/api/workflows', config),
          axios.get('/api/uploads/tags', config)
        ]);
        
        const publishedWorkflows = workflowsData.data.filter(workflow => workflow.status === 'published');
        setWorkflows(publishedWorkflows);
        // Pass the full tag objects instead of just names
        setTags(tagsData.data);
      } catch (error) {
        toast.error('Failed to fetch initial data');
        console.error(`Error fetching initial data:`, error);
      }
    };

    fetchInitialData();
  }, []);

  useEffect(() => {
    const fetchFileList = async () => {
      try {
        const token = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).token : '';
        const response = await axios.get(`/api/uploads?page=${currentPage}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setFileListData({ 
          loading: false, 
          files: response.data.files 
        });
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.error(`Error fetching file list: ${error.message}\n${error.stack}`);
        setFileListData({ loading: false, files: [] });
        toast.error('Failed to fetch uploaded files');
      }
    };

    if (activeTab === 0) {
      setFileListData(prev => ({ ...prev, loading: true }));
      fetchFileList();
    }
  }, [refreshFileList, activeTab, currentPage]);

  const handleTabSelect = (index) => {
    setActiveTab(index);
    if (index === 0) {
      setFileListData(prev => ({ ...prev, loading: true }));
    }
  };

  const Pagination = () => (
    <Flex justify="center" mt={4} gap={2}>
      <Button
        size="sm"
        onClick={() => setCurrentPage(p => Math.max(1, p - 1))}
        isDisabled={currentPage === 1}
      >
        Previous
      </Button>
      <Box alignSelf="center">
        Page {currentPage} of {totalPages}
      </Box>
      <Button
        size="sm"
        onClick={() => setCurrentPage(p => Math.min(totalPages, p + 1))}
        isDisabled={currentPage === totalPages}
      >
        Next
      </Button>
    </Flex>
  );

  return (
    <Box p={4} boxShadow="md" borderRadius="md">
      <Tabs selectedIndex={activeTab} onSelect={handleTabSelect}>
        <TabList>
          <Tab>Uploaded Files</Tab>
          <Tab>Upload New File</Tab>
        </TabList>

        <TabPanel>
          <FileList files={fileListData.files} isLoading={fileListData.loading} />
          {!fileListData.loading && fileListData.files.length > 0 && <Pagination />}
        </TabPanel>
        <TabPanel>
          <DragAndDropFileUpload 
            onFileChange={handleFileChange} 
            selectedFile={file} 
            key={fileKey}
          />
          <Box mt={4}>
            <Button
              onClick={handleNextClick}
              colorScheme="blue"
              isDisabled={!file}
            >
              Next
            </Button>
          </Box>
        </TabPanel>
      </Tabs>

      {isModalOpen && (
        <UploadModal
          isOpen={isModalOpen}
          onRequestClose={() => {
            setIsModalOpen(false);
            setFile(null);
            setHeaders([]);
            setFileKey(uuidv4());
          }}
          file={file}
          headers={headers}
          mapping={mapping}
          onMappingChange={handleMappingChange}
          workflows={workflows}
          selectedWorkflow={selectedWorkflow}
          onWorkflowChange={handleWorkflowChange}
          tags={tags}
          selectedTags={selectedTags}
          onTagChange={handleTagChange}
          onSubmit={handleSubmit}
        />
      )}
    </Box>
  );
};

export default Uploads;
