export const getToken = () => {
  const userInfo = localStorage.getItem('userInfo');
  if (userInfo) {
    try {
      const parsedUserInfo = JSON.parse(userInfo);
      return parsedUserInfo.token;
    } catch (error) {
      console.error('Error parsing userInfo from localStorage:', error.message, error.stack);
      return '';
    }
  } else {
    console.warn('No userInfo found in localStorage');
    return '';
  }
};