import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTachometerAlt, faComments, faAddressBook, faCogs, faFileUpload, faCreditCard, faPhone, faUserCog, faSignOutAlt, faTags, faChartLine, faMobile } from '@fortawesome/free-solid-svg-icons';
import { Box, VStack, Text, Button, Icon, Flex, Tooltip } from '@chakra-ui/react';

const Sidebar = ({ setIsAuthenticated }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    try {
      localStorage.removeItem('userInfo');
      setIsAuthenticated(false);
      navigate('/login');
    } catch (error) {
      console.error(`Error during logout: ${error.message}\n${error.stack}`);
    }
  };

  const SidebarItem = ({ to, icon, text }) => (
    <Link to={to} style={{ display: 'block' }}>
      <Tooltip label={text} placement="right" isDisabled={isExpanded}>
        <Flex
          px={2}
          py={2}
          mx={2}
          my={1}
          borderRadius="md"
          alignItems="center"
          justifyContent={isExpanded ? 'flex-start' : 'center'}
          h="12"
          transition="all 0.3s ease"
          _hover={{
            bg: 'gray.700',
            transform: 'translateY(-2px)',
            boxShadow: 'md',
          }}
        >
          <Box
            minWidth={isExpanded ? '8' : '12'}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Icon 
              as={FontAwesomeIcon} 
              icon={icon} 
              fontSize="20px"
              transition="all 0.3s ease"
              _groupHover={{ color: 'white' }}
            />
          </Box>
          <Box
            ml={isExpanded ? 2 : 0}
            width={isExpanded ? 'auto' : 0}
            opacity={isExpanded ? 1 : 0}
            transition="all 0.3s ease"
            overflow="hidden"
            whiteSpace="nowrap"
          >
            <Text
              _groupHover={{ color: 'white' }}
            >
              {text}
            </Text>
          </Box>
        </Flex>
      </Tooltip>
    </Link>
  );

  return (
    <Box
      position="relative"
      w={isExpanded ? '64' : '16'}
      transition="width 0.3s ease"
      onMouseEnter={() => setIsExpanded(true)}
      onMouseLeave={() => setIsExpanded(false)}
    >
      <Flex
        position="fixed"
        bg="gray.800"
        color="white"
        h="100vh"
        w="inherit"
        flexDirection="column"
        overflow="hidden"
      >
        <Flex 
          alignItems="center" 
          justifyContent="center" 
          h="16" 
          bg="gray.900" 
          shadow="md"
          px={2}
        >
          <Box
            width={isExpanded ? 'auto' : '12'}
            height="12"
            overflow="hidden"
            whiteSpace="nowrap"
            transition="all 0.3s ease"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {isExpanded ? (
              <Text fontSize="2xl" fontWeight="bold">
                Paladin CRM
              </Text>
            ) : (
              <img 
                src="/404-illustration.jpeg" 
                alt="PS Logo" 
                style={{ 
                  maxHeight: '100%', 
                  maxWidth: '100%', 
                  objectFit: 'contain',
                  filter: 'invert(0)' // This line inverts the colors
                }} 
              />
            )}
          </Box>
        </Flex>
        <Box
          overflowY="auto"
          overflowX="hidden"
          flex="1"
          css={{
            '&::-webkit-scrollbar': { 
              width: '6px',
              backgroundColor: 'rgba(0, 0, 0, 0.05)'
            },
            '&::-webkit-scrollbar-thumb': { 
              backgroundColor: 'rgba(255, 255, 255, 0.5)',
              borderRadius: '3px',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.7)'
              }
            }
          }}
        >
          <VStack align="stretch" spacing={0} py={1}>
            <SidebarItem to="/dashboard" icon={faTachometerAlt} text="Dashboard" />
            <SidebarItem to="/conversations" icon={faComments} text="Conversations" />
            <SidebarItem to="/contacts" icon={faAddressBook} text="Contacts" />
            <SidebarItem to="/workflows" icon={faCogs} text="Workflows" />
            <SidebarItem to="/opportunities" icon={faChartLine} text="Opportunities" />
            <SidebarItem to="/tags" icon={faTags} text="Tags" />
            <SidebarItem to="/uploads" icon={faFileUpload} text="Uploads" />
            <SidebarItem to="/phone-numbers" icon={faPhone} text="Phone Numbers" />
            <SidebarItem to="/settings" icon={faUserCog} text="Settings" />
          </VStack>
        </Box>
        <Box p={2} bg="gray.800">
          <Button
            onClick={handleLogout}
            w="full"
            h="12"
            colorScheme="red"
            justifyContent="center"
          >
            <Icon as={FontAwesomeIcon} icon={faSignOutAlt} fontSize="20px" />
            {isExpanded && <Text ml={4}>Logout</Text>}
          </Button>
        </Box>
      </Flex>
    </Box>
  );
};

export default Sidebar;
