import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, Select, useColorModeValue } from '@chakra-ui/react';

const PipelineSelectionModal = ({ isOpen, onClose, onPipelineChange = () => {}, pipelines, onConfirm }) => {
  const bgColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.800', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg={bgColor} color={textColor}>
        <ModalHeader>Select a Pipeline</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Select 
            placeholder="Select pipeline" 
            onChange={(e) => onPipelineChange(e.target.value)}
            bg={bgColor}
            borderColor={borderColor}
          >
            {pipelines.map((pipeline) => (
              <option key={pipeline._id} value={pipeline._id}>
                {pipeline.name}
              </option>
            ))}
          </Select>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onConfirm}>
            Confirm
          </Button>
          <Button variant="ghost" onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PipelineSelectionModal;