import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import NewOpportunityForm from './NewOpportunityForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faPlus, faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Box, Button, Text, IconButton, Flex, Collapse, VStack, Fade, useDisclosure, Heading, Badge, Tooltip, useColorModeValue, Progress, Input, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, Checkbox, Menu, MenuButton, MenuList, MenuItem, Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverCloseButton, PopoverHeader, PopoverBody, PopoverFooter } from '@chakra-ui/react';
import { getToken } from '../utils/auth';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import OpportunityActions from './OpportunityActions';
import MessageView from './MessageView';
import { FaRobot } from 'react-icons/fa';

const OpportunityList = () => {
  const [opportunities, setOpportunities] = useState([]);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [expandedRows, setExpandedRows] = useState({});
  const [error, setError] = useState('');
  const [pipelines, setPipelines] = useState([]);
  const [isSwitchPipelineOpen, setIsSwitchPipelineOpen] = useState(false);
  const [selectedPipeline, setSelectedPipeline] = useState('');
  const [currentContact, setCurrentContact] = useState(null);
  const [selectedContact, setSelectedContact] = useState(null);
  const { isOpen: isMessageViewOpen, onOpen: onMessageViewOpen, onClose: onMessageViewClose } = useDisclosure();
  const navigate = useNavigate();
  const [opportunityContacts, setOpportunityContacts] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);
  const updateQueue = useRef([]);
  const [isSelectionMode, setIsSelectionMode] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState({});
  const [isWorkflowModalOpen, setIsWorkflowModalOpen] = useState(false);
  const [workflows, setWorkflows] = useState([]);
  const [userTags, setUserTags] = useState([]); // Add state for tags

  const fetchOpportunities = async () => {
    try {
      const token = getToken();
      const { data } = await axios.get('/api/opportunities', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setOpportunities(data);
    } catch (error) {
      setError('Failed to fetch opportunities');
      console.error(`Error fetching opportunities: ${error.message}\n${error.stack}`);
    }
  };

  const fetchAllContacts = async () => {
    try {
      const token = getToken();
      const contactPromises = opportunities.map(opportunity =>
        axios.get(`/api/opportunities/${opportunity._id}/contacts`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
      );
      const contactResponses = await Promise.all(contactPromises);
      const contactsData = contactResponses.reduce((acc, response, index) => {
        acc[opportunities[index]._id] = response.data.contacts;
        return acc;
      }, {});
      setOpportunityContacts(contactsData);
    } catch (error) {
      console.error(`Error fetching all contacts: ${error.message}\n${error.stack}`);
    }
  };

  const fetchPipelines = async () => {
    try {
      const token = getToken();
      const { data } = await axios.get('/api/opportunities/switch', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setPipelines(data);
    } catch (error) {
      console.error(`Error fetching pipelines: ${error.message}\n${error.stack}`);
    }
  };

  const fetchTags = useCallback(async () => {
    try {
      const token = getToken();
      const response = await axios.get('/api/tags', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUserTags(response.data.map(tag => tag.name));
    } catch (error) {
      console.error('Error fetching tags:', error);
      toast.error('Failed to fetch tags');
    }
  }, []);

  useEffect(() => {
    fetchTags();
    fetchOpportunities();
  }, [fetchTags]);

  useEffect(() => {
    if (opportunities.length > 0) {
      fetchAllContacts();
    }
  }, [opportunities]);

  useEffect(() => {
    fetchPipelines();
  }, []);

  const handleNewOpportunityCreated = (newOpportunity) => {
    setOpportunities([newOpportunity, ...opportunities]);
    window.location.reload();
  };

  const handleContactRemoved = (contactId) => {
    setOpportunities(prevOpportunities =>
      prevOpportunities.map(opportunity => ({
        ...opportunity,
        contacts: opportunity.contacts.filter(contact => contact._id !== contactId)
      }))
    );
  };

  const handlePipelineUpdated = useCallback((contact, newPipelineId) => {
    console.log('handlePipelineUpdated called', { contact, newPipelineId });
    
    if (!contact || !contact._id) {
      console.error('Invalid contact object:', contact);
      return;
    }

    // Add the update to the queue
    updateQueue.current.push({ contact, newPipelineId });

    // If an update is already in progress, don't start a new one
    if (isUpdating) {
      console.log('Update already in progress, queued for later');
      return;
    }

    const processQueue = async () => {
      setIsUpdating(true);

      while (updateQueue.current.length > 0) {
        const { contact, newPipelineId } = updateQueue.current.shift();
        
        try {
          const token = getToken();
          await axios.put(`/api/contacts/${contact._id}/opportunity`, { opportunityId: newPipelineId }, {
            headers: { Authorization: `Bearer ${token}` }
          });

          console.log('API call successful, updating state');
          setOpportunities(prevOpportunities => {
            return prevOpportunities.map(opportunity => {
              if (opportunity.contacts && opportunity.contacts.some(c => c._id === contact._id)) {
                return {
                  ...opportunity,
                  contacts: opportunity.contacts.filter(c => c._id !== contact._id)
                };
              }
              if (opportunity._id === newPipelineId) {
                return {
                  ...opportunity,
                  contacts: [...(opportunity.contacts || []), contact]
                };
              }
              return opportunity;
            });
          });
        } catch (error) {
          console.error('Error updating contact opportunity:', error);
          // Handle the error (e.g., show a toast notification)
        }
      }

      setIsUpdating(false);
    };

    processQueue();
  }, [isUpdating]); // Add isUpdating to the dependency array

  const toggleRow = async (id) => {
    setExpandedRows(prevState => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  const handleRemoveFromPipeline = async (contact) => {
    try {
      const token = getToken();
      await axios.put(`/api/contacts/${contact._id}/opportunity`, { opportunityId: null }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      toast.success('Contact removed from pipeline');
      handleContactRemoved(contact._id);
    } catch (error) {
      toast.error('Failed to remove contact from pipeline');
      console.error(`Error removing contact from pipeline: ${error.message}\n${error.stack}`);
    }
  };

  const handlePipelineChange = (pipelineId) => {
    setSelectedPipeline(pipelineId);
  };

  const confirmSwitchPipeline = async () => {
    if (!selectedPipeline) {
      toast.error('Please select a pipeline to switch to');
      return;
    }

    try {
      const token = getToken();
      await axios.put(`/api/contacts/${currentContact._id}/opportunity`, { opportunityId: selectedPipeline }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      toast.success('Contact moved to new pipeline');
      handleContactRemoved(currentContact._id);
      setIsSwitchPipelineOpen(false);
      handlePipelineUpdated(currentContact, selectedPipeline);
    } catch (error) {
      toast.error('Failed to move contact to new pipeline');
      console.error(`Error moving contact to new pipeline: ${error.message}\n${error.stack}`);
    }
  };

  const handleOpenMessageView = (contact) => {
    setSelectedContact(contact);
    onMessageViewOpen();
  };

  const handleCloseMessageView = () => {
    setSelectedContact(null);
    onMessageViewClose();
  };

  const handleEditOpportunityName = async (opportunityId, newName) => {
    try {
      const token = getToken();
      await axios.put(`/api/opportunities/${opportunityId}`, { name: newName }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setOpportunities(opportunities.map(opp => 
        opp._id === opportunityId ? { ...opp, name: newName } : opp
      ));
      toast.success('Pipeline name updated successfully');
    } catch (error) {
      console.error(`Error updating pipeline name: ${error.message}\n${error.stack}`);
      toast.error('Failed to update pipeline name');
    }
  };

  const handleDeleteOpportunity = async (opportunityId) => {
    if (window.confirm('Are you sure you want to delete this pipeline?')) {
      try {
        const token = getToken();
        await axios.delete(`/api/opportunities/${opportunityId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setOpportunities(opportunities.filter(opp => opp._id !== opportunityId));
        toast.success('Pipeline deleted successfully');
      } catch (error) {
        console.error(`Error deleting pipeline: ${error.message}\n${error.stack}`);
        toast.error('Failed to delete pipeline');
      }
    }
  };

  const toggleSelectionMode = () => {
    setIsSelectionMode(!isSelectionMode);
    setSelectedContacts({});
  };

  const handleContactSelection = (contactId) => {
    setSelectedContacts(prev => ({
      ...prev,
      [contactId]: !prev[contactId]
    }));
  };

  const handleBulkAction = async (action) => {
    const selectedContactIds = Object.keys(selectedContacts).filter(id => selectedContacts[id]);
    
    if (selectedContactIds.length === 0) {
      toast.warning('Please select contacts first');
      return;
    }

    switch (action) {
      case 'addToWorkflow':
        // Check contacts' workflow status before opening modal
        try {
          const token = getToken();
          const checkPromises = selectedContactIds.map(contactId =>
            axios.get(`/api/individual-drips/contact/${contactId}`, {
              headers: { Authorization: `Bearer ${token}` }
            })
          );

          const results = await Promise.all(checkPromises);
          const contactsInWorkflow = results
            .filter(result => result.data.length > 0)
            .map(result => result.data[0].contact);

          if (contactsInWorkflow.length > 0) {
            // Uncheck contacts that are already in workflows
            const newSelectedContacts = { ...selectedContacts };
            contactsInWorkflow.forEach(contact => {
              delete newSelectedContacts[contact];
            });
            setSelectedContacts(newSelectedContacts);

            // Show alert
            toast.warning(
              `${contactsInWorkflow.length} contact(s) were unselected because they are already in workflows`
            );

            // Check if there are any contacts left to add to workflow
            const remainingContacts = Object.keys(newSelectedContacts).filter(id => newSelectedContacts[id]);
            if (remainingContacts.length === 0) {
              return;
            }
          }

          // Open workflow selection modal if there are eligible contacts
          setIsWorkflowModalOpen(true);
        } catch (error) {
          console.error('Error checking contacts workflow status:', error);
          toast.error('Failed to check contacts workflow status');
        }
        break;
      // Add more actions as needed
    }
  };

  const handleWorkflowAssignment = async (workflowId) => {
    if (!workflowId) {
      toast.error('Please select a workflow');
      return;
    }

    const selectedContactIds = Object.keys(selectedContacts).filter(id => selectedContacts[id]);
    
    try {
      const token = getToken();
      
      // Double-check workflow status right before assignment
      const checkPromises = selectedContactIds.map(contactId =>
        axios.get(`/api/individual-drips/contact/${contactId}`, {
          headers: { Authorization: `Bearer ${token}` }
        })
      );

      const results = await Promise.all(checkPromises);
      const eligibleContacts = selectedContactIds.filter((contactId, index) => 
        results[index].data.length === 0
      );

      if (eligibleContacts.length === 0) {
        toast.error('No eligible contacts to add to workflow');
        setIsWorkflowModalOpen(false);
        setSelectedContacts({});
        setIsSelectionMode(false);
        return;
      }

      // Proceed with adding eligible contacts to workflow
      const promises = eligibleContacts.map(contactId =>
        axios.post('/api/individual-drips', {
          workflowId,
          contactId
        }, {
          headers: { Authorization: `Bearer ${token}` }
        })
      );

      await Promise.all(promises);
      toast.success(`Added ${eligibleContacts.length} contacts to workflow`);
      setIsWorkflowModalOpen(false);
      setSelectedContacts({});
      setIsSelectionMode(false);
    } catch (error) {
      console.error('Error adding contacts to workflow:', error);
      toast.error(error.response?.data?.message || 'Failed to add contacts to workflow');
    }
  };

  const handleUpdateContact = useCallback((updatedContact) => {
    setOpportunities(prevOpportunities =>
      prevOpportunities.map(opportunity => ({
        ...opportunity,
        contacts: opportunity.contacts?.map(contact =>
          contact._id === updatedContact._id ? updatedContact : contact
        ) || []
      }))
    );
  }, []);

  // Add useEffect to fetch workflows
  useEffect(() => {
    const fetchWorkflows = async () => {
      try {
        const token = getToken();
        const response = await axios.get('/api/workflows', {
          headers: { Authorization: `Bearer ${token}` }
        });
        setWorkflows(response.data);
      } catch (error) {
        console.error('Error fetching workflows:', error);
        toast.error('Failed to fetch workflows');
      }
    };

    fetchWorkflows();
  }, []);

  return (
    <Box p={6} boxShadow="xl" borderRadius="xl" bg={useColorModeValue('white', 'gray.800')}>
      <Flex justifyContent="space-between" alignItems="center" mb={6}>
        <Heading size="lg">Pipelines</Heading>
        <Flex>
          <Button
            onClick={toggleSelectionMode}
            colorScheme={isSelectionMode ? "red" : "blue"}
            size="md"
            mr={4}
          >
            {isSelectionMode ? "Cancel Selection" : "Select Contacts"}
          </Button>
          {isSelectionMode && (
            <Menu>
              <MenuButton as={Button} rightIcon={<FontAwesomeIcon icon={faChevronDown} />} mr={4}>
                Actions
              </MenuButton>
              <MenuList>
                <MenuItem 
                  onClick={() => handleBulkAction('addToWorkflow')}
                  icon={<FaRobot />}
                >
                  Add to Workflow
                </MenuItem>
                {/* Add more menu items for other actions */}
              </MenuList>
            </Menu>
          )}
          <Button
            leftIcon={<FontAwesomeIcon icon={faPlus} />}
            onClick={() => setIsFormOpen(true)}
            colorScheme="blue"
            size="md"
            fontWeight="bold"
            _hover={{ transform: 'translateY(-2px)', boxShadow: 'lg' }}
            transition="all 0.2s"
          >
            Add New Pipeline
          </Button>
        </Flex>
      </Flex>
      {isFormOpen && (
        <NewOpportunityForm
          onClose={() => setIsFormOpen(false)}
          onOpportunityCreated={handleNewOpportunityCreated}
        />
      )}
      {error ? (
        <Text color="red.500">{error}</Text>
      ) : (
        <Fade in={true}>
          <VStack spacing={4} align="stretch">
            {opportunities.map(opportunity => (
              <OpportunityItem
                key={opportunity._id}
                opportunity={opportunity}
                contacts={opportunityContacts[opportunity._id] || []}
                expandedRows={expandedRows}
                toggleRow={toggleRow}
                handleRemoveFromPipeline={handleRemoveFromPipeline}
                handlePipelineUpdated={handlePipelineUpdated}
                pipelines={pipelines}
                navigate={navigate}
                onEdit={handleEditOpportunityName}
                onDelete={handleDeleteOpportunity}
                onOpenMessageView={handleOpenMessageView}
                isSelectionMode={isSelectionMode}
                selectedContacts={selectedContacts}
                onContactSelection={handleContactSelection}
              />
            ))}
          </VStack>
        </Fade>
      )}
      <Modal isOpen={isMessageViewOpen} onClose={handleCloseMessageView} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            {selectedContact && (
              <MessageView
                contact={selectedContact}
                onUpdateContact={handleUpdateContact}
                isInModal={true}
                onClose={handleCloseMessageView}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
      {/* Modify workflow selection modal */}
      <Modal isOpen={isWorkflowModalOpen} onClose={() => setIsWorkflowModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Select Workflow</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}> {/* Added bottom padding */}
            <VStack spacing={4} align="stretch"> {/* Increased spacing between buttons */}
              {workflows
                .filter(workflow => workflow.status === 'published')
                .map(workflow => (
                  <Button
                    key={workflow._id}
                    onClick={() => handleWorkflowAssignment(workflow._id)}
                    variant="outline"
                    justifyContent="flex-start"
                    size="md" // Explicit size for consistency
                    py={3} // Added vertical padding to buttons
                  >
                    {workflow.name}
                  </Button>
                ))}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

const OpportunityItem = ({ opportunity, contacts, expandedRows, toggleRow, handleRemoveFromPipeline, handlePipelineUpdated, pipelines, navigate, onEdit, onDelete, onOpenMessageView, isSelectionMode, selectedContacts, onContactSelection }) => {
  const { isOpen, onToggle } = useDisclosure();
  const [isEditing, setIsEditing] = useState(false);
  const [editName, setEditName] = useState(opportunity.name);
  const bgColor = useColorModeValue('gray.50', 'gray.700');
  const hoverBgColor = useColorModeValue('gray.100', 'gray.600');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  const totalContacts = contacts.length;
  const completedContacts = contacts.filter(contact => contact.state === 'Completed').length;
  const progressPercentage = totalContacts > 0 ? (completedContacts / totalContacts) * 100 : 0;

  const handleEditSubmit = () => {
    onEdit(opportunity._id, editName);
    setIsEditing(false);
  };

  const allContactsSelected = contacts.length > 0 && contacts.every(contact => selectedContacts[contact._id]);

  const handleSelectAllContacts = (e) => {
    if (contacts.length === 0) return; // Prevent action if there are no contacts
    const isChecked = e.target.checked;
    contacts.forEach(contact => {
      onContactSelection(contact._id, isChecked);
    });
  };

  const renderHeaderActions = () => (
    <Flex align="center">
      <Tooltip label="Edit Pipeline Name">
        <IconButton
          icon={<FontAwesomeIcon icon={faPencilAlt} />}
          size="sm"
          mr={2}
          onClick={(e) => {
            e.stopPropagation();
            setIsEditing(!isEditing);
          }}
        />
      </Tooltip>
      <Tooltip label="Delete Pipeline">
        <IconButton
          icon={<FontAwesomeIcon icon={faTrashAlt} />}
          size="sm"
          colorScheme="red"
          mr={4}
          onClick={(e) => {
            e.stopPropagation();
            onDelete(opportunity._id);
          }}
        />
      </Tooltip>
      <Box
        as={FontAwesomeIcon}
        icon={faChevronDown}
        transform={isOpen ? 'rotate(180deg)' : 'rotate(0deg)'}
        transition="transform 0.3s"
        cursor="pointer"
        onClick={(e) => {
          e.stopPropagation();
          toggleRow(opportunity._id);
          onToggle();
        }}
      />
    </Flex>
  );

  return (
    <Box
      borderWidth="1px"
      borderRadius="xl"
      borderColor={borderColor}
      overflow="hidden"
      boxShadow="md"
      _hover={{ boxShadow: 'xl' }}
      transition="all 0.2s"
    >
      <Flex
        justify="space-between"
        align="center"
        p={4}
        bg={bgColor}
        _hover={{ bg: hoverBgColor }}
        onClick={() => {
          toggleRow(opportunity._id);
          onToggle();
        }}
      >
        <Flex align="center" flex={1}>
          {isEditing ? (
            <Input
              value={editName}
              onChange={(e) => setEditName(e.target.value)}
              onBlur={handleEditSubmit}
              onKeyPress={(e) => e.key === 'Enter' && handleEditSubmit()}
              mr={2}
            />
          ) : (
            <Text fontSize="xl" fontWeight="bold" mr={4}>{opportunity.name}</Text>
          )}
          <Badge colorScheme="blue" fontSize="sm">{totalContacts} contacts</Badge>
        </Flex>
        {renderHeaderActions()}
      </Flex>
      <Collapse in={expandedRows[opportunity._id]} animateOpacity>
        <Box p={4}>
          <Progress value={progressPercentage} size="sm" colorScheme="green" mb={4} />
          <Flex fontWeight="bold" mb={2} borderBottom="1px" borderColor={borderColor} pb={2}>
            {isSelectionMode && (
              <Box width="40px">
                <Checkbox
                  isChecked={allContactsSelected}
                  onChange={handleSelectAllContacts}
                  isDisabled={contacts.length === 0}
                />
              </Box>
            )}
            <Box flex={2} textAlign="center">Name</Box>  {/* Adjusted flex */}
            <Box flex={2} textAlign="center">Phone</Box>  {/* Adjusted flex */}
            <Box flex={2} textAlign="center">Email</Box>  {/* Adjusted flex */}
            <Box flex={1} textAlign="center">State</Box>  {/* Adjusted flex */}
            <Box flex={1} textAlign="center">Actions</Box>  {/* Adjusted flex */}
          </Flex>
          {contacts.length > 0 ? (
            contacts.map(contact => (
              <ContactItem
                key={contact._id}
                contact={contact}
                opportunity={opportunity}
                handleRemoveFromPipeline={handleRemoveFromPipeline}
                pipelines={pipelines}
                handlePipelineUpdated={handlePipelineUpdated}
                navigate={navigate}
                onOpenMessageView={onOpenMessageView}
                hoverBgColor={hoverBgColor}
                isSelectionMode={isSelectionMode}
                isSelected={selectedContacts[contact._id]}
                onContactSelection={onContactSelection}
              />
            ))
          ) : (
            <Text mt={2}>No contacts assigned to this pipeline.</Text>
          )}
        </Box>
      </Collapse>
    </Box>
  );
};

const ContactItem = ({ contact, opportunity, handleRemoveFromPipeline, pipelines, handlePipelineUpdated, navigate, onOpenMessageView, hoverBgColor, isSelectionMode, isSelected, onContactSelection }) => {
  return (
    <Flex
      py={2}
      px={3}
      borderRadius="md"
      transition="all 0.2s"
      align="center"
    >
      <Flex flex={8} align="center" mr={2}  // Adjusted flex value
        _hover={{
          bg: hoverBgColor,
          transform: 'scale(1.02)',
          boxShadow: 'md',
        }}
        borderRadius="md"
        p={2}
      >
        {isSelectionMode && (
          <Box width="40px">
            <Checkbox
              isChecked={isSelected}
              onChange={() => onContactSelection(contact._id)}
            />
          </Box>
        )}
        <Box flex={2} textAlign="center">{contact.firstName} {contact.lastName}</Box>  {/* Adjusted flex */}
        <Box flex={2} textAlign="center">{contact.phone}</Box>  {/* Adjusted flex */}
        <Box flex={2} textAlign="center">{contact.email}</Box>  {/* Adjusted flex */}
        <Box flex={1} textAlign="center">
          <Badge colorScheme={contact.state === 'Completed' ? 'green' : 'yellow'}>
            {contact.state}
          </Badge>
        </Box>
      </Flex>
      <Box flex={1} textAlign="center">  {/* Adjusted flex */}
        <OpportunityActions
          contact={contact}
          onContactRemoved={handleRemoveFromPipeline}
          pipelines={pipelines}
          currentPipelineId={opportunity._id}
          onPipelineUpdated={handlePipelineUpdated}
          navigate={navigate}
          onOpenMessageView={onOpenMessageView}
        />
      </Box>
    </Flex>
  );
};

export default OpportunityList;
