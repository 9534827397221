import React, { useState } from 'react';
import axios from 'axios';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';

const NewTagForm = ({ isOpen, onClose, onTagCreated }) => {
  const [name, setName] = useState('');
  const [color, setColor] = useState('#3182CE'); // Default blue color

  const bgColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.800', 'white');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = JSON.parse(localStorage.getItem('userInfo')).token;
      const { data } = await axios.post('/api/tags', { name, color }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      onTagCreated(data);
      setName('');
      setColor('#3182CE');
    } catch (error) {
      console.error('Error creating tag:', error);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg={bgColor} color={textColor}>
        <ModalHeader>Create New Tag</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <VStack spacing={6}>
              <FormControl isRequired>
                <FormLabel>Tag Name</FormLabel>
                <Input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Enter tag name"
                  size="lg"
                  borderRadius="md"
                />
              </FormControl>
              <FormControl>
                <FormLabel>Tag Color</FormLabel>
                <Input
                  type="color"
                  value={color}
                  onChange={(e) => setColor(e.target.value)}
                  size="lg"
                  borderRadius="md"
                />
              </FormControl>
            </VStack>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSubmit} size="lg" borderRadius="md">
            Create
          </Button>
          <Button variant="ghost" onClick={onClose} size="lg" borderRadius="md">Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default NewTagForm;