import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {
  Box, Button, FormControl, FormLabel, Input, VStack, HStack, Text, Spinner, Wrap, WrapItem,
  Tag, TagLabel, TagCloseButton, Flex, Select, SimpleGrid, IconButton, useColorModeValue,
  Container, Heading, Divider, Avatar, Badge, Card, CardHeader, CardBody, Stack
} from '@chakra-ui/react';
import { EditIcon, PhoneIcon, EmailIcon, InfoIcon, CheckIcon, CloseIcon } from '@chakra-ui/icons';

const TagSelector = ({ availableTags = [], selectedTags = [], onTagChange }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleTagClick = (tag) => {
    const newSelectedTags = selectedTags.includes(tag)
      ? selectedTags.filter(t => t !== tag)
      : [...selectedTags, tag];
    onTagChange(newSelectedTags);
  };

  const filteredTags = availableTags.filter(tag => 
    tag.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box>
      <Input
        placeholder="Search tags..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        mb={4}
      />
      <Flex direction="column">
        <Box mb={4}>
          <Text fontWeight="bold" mb={2}>Available Tags:</Text>
          <Wrap>
            {filteredTags.map(tag => (
              <WrapItem key={tag}>
                <Tag 
                  colorScheme={selectedTags.includes(tag) ? "green" : "gray"} 
                  size="md" 
                  cursor="pointer"
                  onClick={() => handleTagClick(tag)}
                >
                  <TagLabel>{tag}</TagLabel>
                </Tag>
              </WrapItem>
            ))}
          </Wrap>
        </Box>
        <Box>
          <Text fontWeight="bold" mb={2}>Added Tags:</Text>
          <Wrap>
            {selectedTags.map(tag => (
              <WrapItem key={tag}>
                <Tag colorScheme="blue" size="md">
                  <TagLabel>{tag}</TagLabel>
                  <TagCloseButton onClick={() => handleTagClick(tag)} />
                </Tag>
              </WrapItem>
            ))}
          </Wrap>
        </Box>
      </Flex>
    </Box>
  );
};

const ContactProfile = () => {
  const { id } = useParams();
  const [contact, setContact] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [editedContact, setEditedContact] = useState(null);
  const [opportunities, setOpportunities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [availableTags, setAvailableTags] = useState([]);

  const bgColor = useColorModeValue('gray.50', 'gray.800');
  const cardBgColor = useColorModeValue('white', 'gray.700');
  const textColor = useColorModeValue('gray.800', 'white');
  const inputBgColor = useColorModeValue('white', 'gray.700');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).token : '';
        const [contactResponse, opportunitiesResponse, tagsResponse] = await Promise.all([
          axios.get(`/api/contacts/${id}`, { headers: { Authorization: `Bearer ${token}` } }),
          axios.get('/api/opportunities', { headers: { Authorization: `Bearer ${token}` } }),
          axios.get('/api/tags', { headers: { Authorization: `Bearer ${token}` } })
        ]);

        const contactData = {
          ...contactResponse.data,
          tags: Array.isArray(contactResponse.data.tags) 
            ? contactResponse.data.tags 
            : contactResponse.data.tags.split(',').map(tag => tag.trim())
        };

        setContact(contactData);
        setEditedContact(contactData);
        setOpportunities(opportunitiesResponse.data);
        setAvailableTags(tagsResponse.data.map(tag => tag.name));
        setLoading(false);
      } catch (error) {
        console.error(`Error fetching data: ${error.message}\n${error.stack}`);
        setLoading(false);
      }
    };
    fetchData();
  }, [id]);

  const handleEdit = () => setEditMode(true);

  const handleSave = async () => {
    try {
      const token = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).token : '';
      
      const contactToSend = {
        ...editedContact,
        tags: editedContact.tags.join(',')
      };
  
      const response = await axios.put(`/api/contacts/${contact._id}`, contactToSend, {
        headers: { Authorization: `Bearer ${token}` }
      });
      
      const updatedContact = {
        ...response.data,
        tags: editedContact.tags
      };
      setContact(updatedContact);
      setEditedContact(updatedContact);
      setEditMode(false);
    } catch (error) {
      console.error(`Error updating contact: ${error.message}\n${error.stack}`);
    }
  };

  const handleCancel = () => {
    setEditedContact(contact);
    setEditMode(false);
  };

  const handleChange = (field, value) => setEditedContact({ ...editedContact, [field]: value });

  const handleOpportunityChange = async (e) => {
    const opportunityId = e.target.value;
    try {
      const token = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).token : '';
      await axios.put(`/api/contacts/${contact._id}/opportunity`, { opportunityId }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      const updatedContact = { ...contact, opportunity: opportunityId };
      setContact(updatedContact);
      setEditedContact(updatedContact);
    } catch (error) {
      console.error(`Error updating opportunity: ${error.message}\n${error.stack}`);
    }
  };

  const handleTagsChange = (selectedTags) => setEditedContact({ ...editedContact, tags: selectedTags });

  if (loading) return (
    <Flex justify="center" align="center" height="100vh" bg={bgColor}>
      <Spinner size="xl" thickness="4px" speed="0.65s" color="blue.500" />
    </Flex>
  );

  if (!contact) return (
    <Box textAlign="center" fontSize="xl" mt={10} bg={bgColor} height="100vh" pt={20}>
      Contact not found
    </Box>
  );

  const fields = [
    { name: 'firstName', label: 'First Name', icon: InfoIcon },
    { name: 'lastName', label: 'Last Name', icon: InfoIcon },
    { name: 'phone', label: 'Phone', icon: PhoneIcon },
    { name: 'email', label: 'Email', icon: EmailIcon },
    { name: 'address', label: 'Address', icon: InfoIcon },
    { name: 'city', label: 'City', icon: InfoIcon },
    { name: 'state', label: 'State', icon: InfoIcon },
  ];

  return (
    <Box bg={bgColor} minHeight="100vh" py={8}>
      <Container maxWidth="4xl">
        <Card bg={cardBgColor} shadow="xl" borderRadius="lg" overflow="hidden">
          <CardHeader>
            <Flex justify="space-between" align="center">
              <HStack spacing={4}>
                <Avatar size="xl" name={`${contact.firstName} ${contact.lastName}`} src={contact.avatar} />
                <Box>
                  <Heading size="lg" color={textColor}>
                    {contact.firstName} {contact.lastName}
                  </Heading>
                  <Text color="gray.500">{contact.email}</Text>
                </Box>
              </HStack>
              {!editMode && (
                <IconButton
                  aria-label="Edit contact"
                  icon={<EditIcon />}
                  onClick={handleEdit}
                  colorScheme="blue"
                  variant="outline"
                  size="lg"
                />
              )}
            </Flex>
          </CardHeader>
          <Divider />
          <CardBody>
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
              {fields.map((field) => (
                <FormControl key={field.name}>
                  <FormLabel color="gray.500">
                    <HStack spacing={2}>
                      <field.icon />
                      <Text>{field.label}</Text>
                    </HStack>
                  </FormLabel>
                  {editMode ? (
                    <Input
                      value={editedContact[field.name]}
                      onChange={(e) => handleChange(field.name, e.target.value)}
                      bg={inputBgColor}
                    />
                  ) : (
                    <Text fontWeight="medium" color={textColor}>{contact[field.name]}</Text>
                  )}
                </FormControl>
              ))}
            </SimpleGrid>
            
            <Stack spacing={6} mt={8}>
              <FormControl>
                <FormLabel color="gray.500">Tags</FormLabel>
                {editMode ? (
                  <TagSelector
                    availableTags={availableTags}
                    selectedTags={editedContact.tags}
                    onTagChange={handleTagsChange}
                  />
                ) : (
                  <Wrap>
                    {contact.tags.map((tag, index) => (
                      <WrapItem key={index}>
                        <Badge colorScheme="blue" fontSize="0.8em" p={2} borderRadius="full">
                          {tag}
                        </Badge>
                      </WrapItem>
                    ))}
                  </Wrap>
                )}
              </FormControl>
              
              <FormControl>
                <FormLabel color="gray.500">Pipeline</FormLabel>
                <Select
                  value={contact.opportunity || ''}
                  onChange={handleOpportunityChange}
                  bg={inputBgColor}
                >
                  <option value="">Select Opportunity</option>
                  {opportunities.map(opportunity => (
                    <option key={opportunity._id} value={opportunity._id}>
                      {opportunity.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Stack>

            {editMode && (
              <HStack justify="flex-end" spacing={4} mt={8}>
                <Button onClick={handleCancel} variant="outline" leftIcon={<CloseIcon />}>
                  Cancel
                </Button>
                <Button onClick={handleSave} colorScheme="green" leftIcon={<CheckIcon />}>
                  Save
                </Button>
              </HStack>
            )}
          </CardBody>
        </Card>
      </Container>
    </Box>
  );
};

export default ContactProfile;