import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Box, Flex, Text, useColorModeValue, Center, Skeleton, VStack } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import axios from 'axios';
import ConversationList from '../components/ConversationList';
import MessageView from '../components/MessageView';
import ContactInfo from '../components/ContactInfo';
import sseManager from '../utils/sseManager';
import { useLocation } from 'react-router-dom';

const ContactInfoSkeleton = () => (
  <Box p={4}>
    <VStack spacing={4} align="stretch">
      <Skeleton height="40px" />
      <Skeleton height="20px" />
      <Skeleton height="20px" />
      <Skeleton height="20px" />
      <Skeleton height="100px" />
      <Skeleton height="40px" />
    </VStack>
  </Box>
);

const Conversations = () => {
  const [selectedContact, setSelectedContact] = useState(null);
  const [contacts, setContacts] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [contactCache, setContactCache] = useState({});
  const [hasMore, setHasMore] = useState(true);
  const [activeTab, setActiveTab] = useState('all');
  const [userTags, setUserTags] = useState({});
  const location = useLocation();

  const fetchingRef = useRef(false);
  const activeTabRef = useRef(activeTab);

  const backgroundColor = useColorModeValue('gray.50', 'gray.900');
  const borderColor = useColorModeValue('gray.200', 'gray.700');

  const fetchContacts = useCallback(async (pageNumber, tab) => {
    if (fetchingRef.current) return;
    fetchingRef.current = true;
    setLoading(true);
    
    try {
      const token = JSON.parse(localStorage.getItem('userInfo')).token;
      const response = await axios.get(`/api/conversations?page=${pageNumber}&limit=15&tab=${tab || activeTab}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      
      if (response.data && response.data.contacts) {
        // Always replace the entire list when fetching page 1
        if (pageNumber === 1) {
          setContacts(response.data.contacts);
        } else {
          setContacts(prevContacts => [...prevContacts, ...response.data.contacts]);
        }
        setHasMore(response.data.contacts.length === 15);
      }
    } catch (error) {
      setError('Failed to fetch contacts');
      toast.error('Failed to fetch contacts');
    } finally {
      setLoading(false);
      fetchingRef.current = false;
    }
  }, [activeTab]);

  useEffect(() => {
    activeTabRef.current = activeTab;
  }, [activeTab]);

  useEffect(() => {
    setPage(1);
    fetchContacts(1, activeTab);
  }, [activeTab, fetchContacts]);

  const loadMore = useCallback(() => {
    if (!loading && hasMore && !fetchingRef.current) {
      const scrollContainer = document.getElementById('scrollableDiv');
      const scrollHeight = scrollContainer?.scrollHeight;
      const scrollTop = scrollContainer?.scrollTop;
      
      const nextPage = page + 1;
      setPage(nextPage);
      
      fetchContacts(nextPage, activeTabRef.current).then(() => {
        // Only adjust scroll if we're near the bottom
        if (scrollContainer && scrollHeight && scrollTop) {
          const isNearBottom = scrollTop + scrollContainer.clientHeight >= scrollHeight - 100;
          if (!isNearBottom) {
            scrollContainer.scrollTop = scrollTop;
          }
        }
      });
    }
  }, [loading, hasMore, page, fetchContacts]);

  const handleTabChange = useCallback((newTab) => {
    if (newTab !== activeTab) {  // Only proceed if the tab is actually changing
      setActiveTab(newTab);
      setPage(1);
      setContacts([]);
    }
  }, [activeTab]);

  const updateContactWithNewMessage = useCallback((contactId, newMessage, currentCache) => {
    const isIncoming = newMessage.direction === 'inbound';

    // Update the cache
    setContactCache((prevCache) => {
      const existingContact = prevCache[contactId] || {};
      const updatedMessages = [...(existingContact.messages || []), newMessage];
      
      return {
        ...prevCache,
        [contactId]: {
          ...existingContact,
          lastMessage: newMessage,
          lastMessageAt: newMessage.timestamp,
          unread: isIncoming ? true : existingContact.unread,
          messages: updatedMessages,
        },
      };
    });

    // Update contacts list
    setContacts(prevContacts => {
      const existingContact = prevContacts.find(c => c._id === contactId);
      if (existingContact) {
        const updatedContact = {
          ...existingContact,
          lastMessage: newMessage,
          lastMessageAt: newMessage.timestamp,
          unread: isIncoming ? true : existingContact.unread,
        };
        return [
          updatedContact,
          ...prevContacts.filter(c => c._id !== contactId)
        ];
      }
      return prevContacts;
    });

    // Update selected contact if needed
    if (selectedContact && selectedContact._id === contactId) {
      setSelectedContact((prevContact) => ({
        ...prevContact,
        messages: [...(prevContact.messages || []), newMessage],
        lastMessage: newMessage,
        lastMessageAt: newMessage.timestamp,
        unread: isIncoming ? true : prevContact.unread,
      }));
    }
  }, [selectedContact]);

  const handleDripUpdate = useCallback((contactId, updatedDrip) => {
    setContacts((prevContacts) => {
      return prevContacts.map((contact) => {
        if (contact._id === contactId) {
          const updatedMessages = [...(contact.messages || []), {
            text: updatedDrip.message,
            timestamp: updatedDrip.sentAt,
            isIncoming: false,
            isDrip: true
          }];
          return {
            ...contact,
            messages: updatedMessages,
            lastMessage: {
              text: updatedDrip.message,
              timestamp: updatedDrip.sentAt
            }
          };
        }
        return contact;
      });
    });

    if (selectedContact && selectedContact._id === contactId) {
      setSelectedContact((prevContact) => ({
        ...prevContact,
        messages: [...(prevContact.messages || []), {
          text: updatedDrip.message,
          timestamp: updatedDrip.sentAt,
          isIncoming: false,
          isDrip: true
        }],
        lastMessage: {
          text: updatedDrip.message,
          timestamp: updatedDrip.sentAt
        }
      }));
    }
  }, [selectedContact]);

  const addNewContact = useCallback((newContact) => {
    setContacts((prevContacts) => [newContact, ...prevContacts]);
    setContactCache((prevCache) => ({
      ...prevCache,
      [newContact._id]: newContact,
    }));
  }, []);

  const handleSSEEvent = useCallback((data) => {
    console.log('Received SSE event:', data);
    if (data.type === 'messageStatus') {
      // Update contact cache with new message status
      setContactCache(prevCache => {
        const contact = prevCache[data.contactId];
        if (contact) {
          const updatedMessages = contact.messages?.map(msg =>
            msg.messageId === data.messageId
              ? { ...msg, status: data.status }
              : msg
          );
          return {
            ...prevCache,
            [data.contactId]: {
              ...contact,
              messages: updatedMessages
            }
          };
        }
        return prevCache;
      });
    } else if (data.type === 'newMessage' || data.type === 'messageSent') {
      if (data.fullContact) {
        // Handle full contact update
        setContacts(prevContacts => {
          const existingIndex = prevContacts.findIndex(c => c._id === data.contactId);
          if (existingIndex === -1) {
            return [data.fullContact, ...prevContacts];
          } else {
            const updatedContacts = [...prevContacts];
            updatedContacts[existingIndex] = data.fullContact;
            return updatedContacts;
          }
        });

        setContactCache(prevCache => ({
          ...prevCache,
          [data.contactId]: data.fullContact
        }));

        // Add this: Update selectedContact if it matches
        if (selectedContact && selectedContact._id === data.contactId) {
          setSelectedContact(data.fullContact);
        }
      } else {
        // Only update the message if we're not getting a full contact update
        updateContactWithNewMessage(data.contactId, data.message, contactCache);
      }
    } else if (data.type === 'newContact') {
      addNewContact(data.contact);
    }
  }, [updateContactWithNewMessage, addNewContact, contactCache, selectedContact]);

  const isInitialMount = useRef(true);

  // Add fetchTags function
  const fetchTags = useCallback(async () => {
    try {
      const token = JSON.parse(localStorage.getItem('userInfo')).token;
      const response = await axios.get('/api/tags', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      
      // Convert array to object with name as key for easier lookup
      const tagsMap = response.data.reduce((acc, tag) => {
        acc[tag.name] = tag;
        return acc;
      }, {});
      
      setUserTags(tagsMap);
    } catch (error) {
      console.error('Error fetching tags:', error);
      toast.error('Failed to fetch tags');
    }
  }, []);

  useEffect(() => {
    fetchTags();
    fetchContacts(page);
    sseManager.connect();

    sseManager.addListener('newMessage', handleSSEEvent);
    sseManager.addListener('newContact', handleSSEEvent);
    sseManager.addListener('messageSent', handleSSEEvent);
    sseManager.addListener('messageStatus', handleSSEEvent); // Add this line

    return () => {
      sseManager.removeListener('newMessage', handleSSEEvent);
      sseManager.removeListener('newContact', handleSSEEvent);
      sseManager.removeListener('messageSent', handleSSEEvent);
      sseManager.removeListener('messageStatus', handleSSEEvent); // Add this line
    };
  }, []);

  const fetchFullContactData = useCallback(async (contactId) => {
    try {
      const token = JSON.parse(localStorage.getItem('userInfo')).token;
      const response = await axios.get(`/api/contacts/${contactId}?includeMessages=true`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching full contact data:', error);
      toast.error('Failed to fetch contact details');
      throw error;
    }
  }, []);

  const handleSelectContact = useCallback(async (contact) => {
    try {
      let fullContactData;
      
      // Check if we have cached data and it includes messages
      if (contactCache[contact._id]?.messages) {
        fullContactData = contactCache[contact._id];
      } else {
        fullContactData = await fetchFullContactData(contact._id);
        // Update cache with full contact data
        setContactCache(prevCache => ({
          ...prevCache,
          [contact._id]: fullContactData
        }));
      }

      setSelectedContact(fullContactData);

      // If the contact was unread, mark it as read
      if (fullContactData.unread) {
        try {
          const token = JSON.parse(localStorage.getItem('userInfo')).token;
          const response = await axios.put(
            `/api/contacts/${fullContactData._id}/mark-read`,
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (response.data) {
            const updatedContact = { ...fullContactData, unread: false };
            
            // Update contacts list
            setContacts((prevContacts) =>
              prevContacts.map((c) =>
                c._id === updatedContact._id ? updatedContact : c
              )
            );

            // Update contact cache
            setContactCache((prevCache) => ({
              ...prevCache,
              [updatedContact._id]: updatedContact,
            }));

            // Update selected contact
            setSelectedContact(updatedContact);
          }
        } catch (error) {
          console.error('Error marking contact as read:', error);
          toast.error('Failed to mark contact as read');
        }
      }
    } catch (error) {
      console.error('Error fetching full contact data:', error);
      toast.error('Failed to fetch contact details');
    }
  }, [contactCache, fetchFullContactData]);

  const handleUpdateContact = useCallback((updatedContact) => {
    // Update contact cache first
    setContactCache(prevCache => ({
      ...prevCache,
      [updatedContact._id]: {
        ...prevCache[updatedContact._id],
        ...updatedContact,
        messages: updatedContact.messages || prevCache[updatedContact._id]?.messages
      }
    }));

    // Update contacts list
    setContacts(prevContacts => {
      const newContacts = prevContacts.map(contact =>
        contact._id === updatedContact._id
          ? {
              ...contact,
              ...updatedContact,
              messages: updatedContact.messages || contact.messages
            }
          : contact
      );
      return newContacts;
    });

    // Update selected contact if it's the one being updated
    if (selectedContact?._id === updatedContact._id) {
      setSelectedContact(prev => ({
        ...prev,
        ...updatedContact,
        messages: updatedContact.messages || prev.messages
      }));
    }
  }, [selectedContact]);

  const handleSaveContact = async (updatedContact) => {
    try {
      // Log the data being sent
      console.log('Saving contact data:', updatedContact);

      const token = JSON.parse(localStorage.getItem('userInfo')).token;
      const response = await axios.put(
        `/api/contacts/${updatedContact._id}`,
        {
          ...updatedContact,
          // Ensure tags is an array of ObjectIds
          tags: Array.isArray(updatedContact.tags) ? updatedContact.tags : []
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
        }
      );

      if (response.data) {
        setContacts((prevContacts) =>
          prevContacts.map((c) =>
            c._id === updatedContact._id ? response.data : c
          )
        );
        setSelectedContact(response.data);
        toast.success('Contact updated successfully');
      }
    } catch (error) {
      console.error('Error updating contact:', error);
      // Log the actual error response
      if (error.response) {
        console.error('Server error response:', error.response.data);
      }
      toast.error('Failed to update contact');
    }
  };

  // Add a useEffect to handle new messages from SSE
  useEffect(() => {
    const handleNewMessage = (data) => {
      if (data.contactId && selectedContact?._id === data.contactId) {
        // Update the selected contact's messages
        setSelectedContact(prev => {
          if (!prev) return prev;
          return {
            ...prev,
            messages: [...(prev.messages || []), data.message],
            lastMessageAt: new Date(),
            unread: true
          };
        });
      }
    };

    // Subscribe to SSE events
    sseManager.addListener('newMessage', handleNewMessage);

    return () => {
      sseManager.removeListener('newMessage', handleNewMessage);
    };
  }, [selectedContact]);

  return (
    <Flex h="calc(100vh - 64px)" direction="row" bg={backgroundColor}>
      <Box
        w="30%"
        borderRightWidth="1px"
        borderColor={borderColor}
        p={3}
        display="flex"
        flexDirection="column"
        bg={backgroundColor}
      >
        <Box flex="1" overflowY="auto">
          <ConversationList
            contacts={contacts}
            onSelectContact={handleSelectContact}
            selectedContactId={selectedContact?._id}
            onUpdateContact={handleUpdateContact}
            hasMore={hasMore}
            loadMore={loadMore}
            activeTab={activeTab}
            setActiveTab={handleTabChange}
            userTags={userTags}
            loading={loading} // Add loading prop
          />
        </Box>
      </Box>
      <Box
        w="40%"
        display="flex"
        flexDirection="column"
        borderRightWidth="1px"
        borderColor={borderColor}
        bg={backgroundColor}
      >
        <MessageView
          contact={selectedContact}
          onUpdateContact={handleUpdateContact}
          isInModal={false}
        />
      </Box>
      <Box w="30%" p={3} bg={backgroundColor}>
        {loading ? (
          <ContactInfoSkeleton />
        ) : error ? (
          <Text color="red.500" m="auto">
            {error}
          </Text>
        ) : selectedContact ? (
          <ContactInfo 
            contact={selectedContact} 
            onSave={handleSaveContact}
            userTags={userTags}
          />
        ) : (
          <Center height="100%">
            <Text fontSize="xl" color="gray.500" textAlign="center">
              Please select a conversation to display contact details.
            </Text>
          </Center>
        )}
      </Box>
    </Flex>
  );
};

export default Conversations;
