import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Text, useToast, VStack, Icon } from '@chakra-ui/react';
import { FiUpload } from 'react-icons/fi';

const DragAndDropFileUpload = ({ onFileChange, selectedFile }) => {
  const toast = useToast();

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      onFileChange(acceptedFiles[0]);
      toast({
        title: "File selected.",
        description: `${acceptedFiles[0].name} has been selected.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [onFileChange, toast]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: '.csv' });

  return (
    <Box
      {...getRootProps()}
      p={6}
      border="2px dashed"
      borderColor={isDragActive ? 'blue.400' : 'gray.300'}
      borderRadius="md"
      textAlign="center"
      cursor="pointer"
      _hover={{ borderColor: 'blue.400' }}
    >
      <input {...getInputProps()} />
      <VStack spacing={2}>
        <Icon as={FiUpload} w={8} h={8} color={isDragActive ? "blue.500" : "gray.500"} />
        {selectedFile ? (
          <Text fontWeight="bold" color="blue.500">
            Selected file: {selectedFile.name}
          </Text>
        ) : (
          <Text>
            {isDragActive ? "Drop the file here" : "Drag and drop a file here, or click to select a file"}
          </Text>
        )}
      </VStack>
    </Box>
  );
};

export default DragAndDropFileUpload;