import React, { useState } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton } from '@chakra-ui/react';
import TagSelector from './TagSelector';
import { fieldMappings } from '../pages/Uploads';
import { Box, Button, FormControl, FormLabel, Select, Text, useColorModeValue, Switch, Wrap, WrapItem, VStack, Tag, HStack } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { AddIcon, CloseIcon } from '@chakra-ui/icons';

const UploadModal = ({
  isOpen,
  onRequestClose,
  file,
  headers,
  mapping,
  onMappingChange,
  workflows,
  selectedWorkflow,
  onWorkflowChange,
  tags,
  selectedTags,
  onTagChange,
  onSubmit,
}) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [keepDuplicates, setKeepDuplicates] = useState(false);

  const nextStep = () => {
    if (currentStep === 1 && !mapping['Phone']) {
      setErrorMessage('Phone field must be selected.');
      return;
    }
    setErrorMessage('');
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await onSubmit(keepDuplicates);
      setLoading(false);
      onRequestClose();
    } catch (error) {
      setLoading(false);
      toast.error('Failed to upload contacts');
      console.error(`Error uploading contacts: ${error.message}\n${error.stack}`);
    }
  };

  // Function to get available headers for a specific field
  const getAvailableHeaders = (currentField) => {
    const selectedHeaders = Object.values(mapping).filter(value => value !== '');
    return headers.filter(header => 
      header === mapping[currentField] || !selectedHeaders.includes(header)
    );
  };

  const bgColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.800', 'white');

  const handleAddTag = (tagId) => {
    onTagChange([...new Set([...selectedTags, tagId])]);
  };

  const handleRemoveTag = (tagId) => {
    onTagChange(selectedTags.filter(tag => tag !== tagId));
  };

  const renderTagsSection = () => (
    <FormControl mb={4}>
      <FormLabel>Tags</FormLabel>
      <VStack align="start" width="100%" spacing={4}>
        {/* Available Tags - Now on top */}
        <Box width="100%">
          <Text fontSize="sm" mb={2} color="gray.600">Available Tags:</Text>
          <Wrap spacing={2}>
            {tags
              .filter(tag => !selectedTags.includes(tag._id))
              .map(tag => (
                <WrapItem key={tag._id}>
                  <Button
                    size="sm"
                    leftIcon={<AddIcon boxSize={3} />}
                    onClick={() => handleAddTag(tag._id)}
                    backgroundColor={tag.color || 'blue.500'}
                    color="white"
                    _hover={{
                      opacity: 0.8
                    }}
                  >
                    {tag.name}
                  </Button>
                </WrapItem>
              ))}
          </Wrap>
        </Box>
        
        {/* Selected Tags - Now below */}
        <Box width="100%">
          <Text fontSize="sm" mb={2} color="gray.600">Selected Tags:</Text>
          <Wrap spacing={2}>
            {selectedTags.map(tagId => {
              const tag = tags.find(t => t._id === tagId);
              return tag ? (
                <WrapItem key={tagId}>
                  <Tag
                    size="md"
                    backgroundColor={tag.color || 'blue.500'}
                    color="white"
                  >
                    <HStack spacing={2}>
                      <Text>{tag.name}</Text>
                      <CloseIcon 
                        cursor="pointer" 
                        boxSize={2} 
                        onClick={() => handleRemoveTag(tagId)}
                      />
                    </HStack>
                  </Tag>
                </WrapItem>
              ) : null;
            })}
          </Wrap>
        </Box>
      </VStack>
    </FormControl>
  );

  return (
    <Modal isOpen={isOpen} onClose={onRequestClose}>
      <ModalOverlay />
      <ModalContent bg={bgColor} color={textColor}>
        <ModalHeader>Upload Contacts</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box p={4}>
            {currentStep === 1 && (
              <Box>
                <Text fontSize="xl" fontWeight="bold" mb={4}>Map CSV Columns</Text>
                <form>
                  {Object.keys(fieldMappings).map((field, index) => (
                    <FormControl key={index} mb={4}>
                      <FormLabel htmlFor={field}>{field}</FormLabel>
                      <Select
                        name={field}
                        value={mapping[field] || ''}
                        onChange={onMappingChange}
                      >
                        <option value="">Select Column</option>
                        {getAvailableHeaders(field).map((h, idx) => (
                          <option key={idx} value={h.trim()}>{h.trim()}</option>
                        ))}
                      </Select>
                    </FormControl>
                  ))}
                  {errorMessage && <Text color="red.500" fontSize="xs" mt={2}>{errorMessage}</Text>}
                  <Box display="flex" justifyContent="space-between" mt={4}>
                    <Button onClick={onRequestClose} colorScheme="gray">Cancel</Button>
                    <Button onClick={nextStep} colorScheme="blue">Next</Button>
                  </Box>
                </form>
              </Box>
            )}
            {currentStep === 2 && (
              <Box>
                <Text fontSize="xl" fontWeight="bold" mb={4}>Assign Workflow and Tags</Text>
                <form onSubmit={handleSubmit}>
                  <FormControl mb={4}>
                    <FormLabel htmlFor="workflow">Select Workflow</FormLabel>
                    <Select
                      id="workflow"
                      value={selectedWorkflow}
                      onChange={onWorkflowChange}
                    >
                      <option value="">Select Workflow</option>
                      {workflows.map((workflow) => (
                        <option key={workflow._id} value={workflow._id}>
                          {workflow.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                  {renderTagsSection()}
                  <FormControl display="flex" alignItems="center" mb={4}>
                    <FormLabel htmlFor="keep-duplicates" mb="0">
                      Keep Duplicate Contacts
                    </FormLabel>
                    <Switch
                      id="keep-duplicates"
                      isChecked={keepDuplicates}
                      onChange={(e) => setKeepDuplicates(e.target.checked)}
                    />
                  </FormControl>
                  <Box display="flex" justifyContent="space-between" mt={4}>
                    <Button onClick={prevStep} colorScheme="gray">Back</Button>
                    <Button type="submit" colorScheme="blue" isLoading={loading}>Submit</Button>
                  </Box>
                </form>
              </Box>
            )}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default UploadModal;
