import React, { useState } from 'react';
import axios from 'axios';
import Modal from 'react-modal';

const NewOpportunityForm = ({ onClose, onOpportunityCreated }) => {
  const [formData, setFormData] = useState({
    name: '',
  });
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).token : '';
      const { data } = await axios.post('/api/opportunities', formData, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      onOpportunityCreated(data);
      onClose();
    } catch (error) {
      console.error(`Error creating opportunity: ${error.message}\n${error.stack}`);
      setError('Failed to create opportunity. Please try again.');
    }
  };

  return (
    <Modal
      isOpen={true}
      onRequestClose={onClose}
      contentLabel="New Opportunity"
      className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50"
    >
      <div className="bg-white p-6 rounded shadow-md w-full max-w-md">
        <h2 className="text-xl font-bold mb-4">New Opportunity</h2>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700">Name</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="w-full p-2 border rounded"
            />
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="mr-4 p-2 bg-gray-200 rounded"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="p-2 bg-blue-500 text-white rounded"
            >
              Create
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default NewOpportunityForm;