import React, { useState, useEffect } from 'react';
import ContactList from '../components/ContactList';
import { Box, Skeleton, Stack } from '@chakra-ui/react';

const Contacts = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // This effect is to simulate initial loading
    // You may want to replace this with actual data fetching logic
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return (
      <Box p={4}>
        <Stack spacing={4}>
          <Skeleton height="60px" />
          {[...Array(5)].map((_, i) => (
            <Skeleton key={i} height="80px" />
          ))}
        </Stack>
      </Box>
    );
  }

  return (
    <Box p={4} maxWidth="100%" overflow="hidden">
      <ContactList />
    </Box>
  );
};

export default Contacts;
