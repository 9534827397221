import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Switch,
  Text,
  useToast,
  VStack,
  Icon,
  InputGroup,
  InputRightElement,
  useColorModeValue,
  useColorMode,
} from '@chakra-ui/react';
import { FaUser, FaEnvelope, FaLock, FaMoon, FaBell, FaPlus, FaTimes, FaStopCircle } from 'react-icons/fa';

const Settings = () => {
  const [user, setUser] = useState({
    name: '',
    email: '',
    password: '',
    darkMode: false,
  });
  const [customStopTriggers, setCustomStopTriggers] = useState([]);
  const [newStopTrigger, setNewStopTrigger] = useState('');
  const [notifications, setNotifications] = useState(true);
  const [optOutPhrase, setOptOutPhrase] = useState('');
  const toast = useToast();
  const { colorMode, toggleColorMode } = useColorMode();

  const bgColor = useColorModeValue('gray.50', 'gray.700');
  const cardBgColor = useColorModeValue('white', 'gray.800');

  const defaultStopTriggers = [
    'STOP', 'UNSUBSCRIBE', 'CANCEL', 'END', 'QUIT', 'REMOVE',
    'OPT OUT', 'NO MORE', 'LEAVE ME ALONE', 'DO NOT CONTACT'
  ];

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).token : '';
        const { data } = await axios.get('/api/users/profile', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setUser(data);
        console.log('User data in Settings:', data);
      } catch (error) {
        console.error(`Error fetching user data: ${error.message}\n${error.stack}`);
      }
    };

    fetchUserData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  const handleSaveProfile = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).token : '';
      await axios.put('/api/users/profile', { ...user, darkMode: colorMode === 'dark' }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      toast({
        title: 'Profile updated.',
        description: 'Your profile has been successfully updated.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error(`Error updating profile: ${error.message}\n${error.stack}`);
      toast({
        title: 'Update failed.',
        description: 'Failed to update profile. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleAddStopTrigger = () => {
    if (newStopTrigger.trim() && !customStopTriggers.includes(newStopTrigger.trim())) {
      setCustomStopTriggers([...customStopTriggers, newStopTrigger.trim()]);
      setNewStopTrigger('');
    }
  };

  const handleRemoveStopTrigger = (index) => {
    setCustomStopTriggers(customStopTriggers.filter((_, i) => i !== index));
  };

  const handleColorModeChange = async () => {
    const newDarkMode = !user.darkMode;
    setUser({ ...user, darkMode: newDarkMode });
    toggleColorMode();
    
    try {
      const token = JSON.parse(localStorage.getItem('userInfo')).token;
      await axios.put('/api/users/profile', { darkMode: newDarkMode }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      localStorage.setItem('userDarkMode', newDarkMode);
      toast({
        title: 'Dark mode updated.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error(`Error updating dark mode preference: ${error.message}`);
      toast({
        title: 'Update failed.',
        description: 'Failed to update dark mode preference. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Container maxW="container.lg" py={8}>
      <VStack spacing={8} align="stretch">
        <Heading as="h1" size="xl" textAlign="center">User Settings</Heading>

        <Flex direction={{ base: 'column', md: 'row' }} gap={8}>
          <Box flex={1} bg={cardBgColor} p={6} borderRadius="lg" boxShadow="md">
            <Heading as="h2" size="lg" mb={6}>Profile Information</Heading>
            <form onSubmit={handleSaveProfile}>
              <Stack spacing={4}>
                <FormControl>
                  <FormLabel>Name</FormLabel>
                  <InputGroup>
                    <Input name="name" value={user.name} onChange={handleInputChange} placeholder="Your name" />
                    <InputRightElement children={<Icon as={FaUser} color="gray.500" />} />
                  </InputGroup>
                </FormControl>
                <FormControl>
                  <FormLabel>Email</FormLabel>
                  <InputGroup>
                    <Input name="email" type="email" value={user.email} onChange={handleInputChange} placeholder="your@email.com" />
                    <InputRightElement children={<Icon as={FaEnvelope} color="gray.500" />} />
                  </InputGroup>
                </FormControl>
                <FormControl>
                  <FormLabel>Password</FormLabel>
                  <InputGroup>
                    <Input name="password" type="password" value={user.password} onChange={handleInputChange} placeholder="••••••••" />
                    <InputRightElement children={<Icon as={FaLock} color="gray.500" />} />
                  </InputGroup>
                </FormControl>
                <Button type="submit" colorScheme="blue" leftIcon={<FaUser />}>Update Profile</Button>
              </Stack>
            </form>
          </Box>

          <Box flex={1} bg={cardBgColor} p={6} borderRadius="lg" boxShadow="md">
            <Heading as="h2" size="lg" mb={6}>Preferences</Heading>
            <Stack spacing={4}>
              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="dark-mode" mb="0" flex="1">
                  <Flex align="center">
                    <Icon as={FaMoon} mr={2} />
                    Dark Mode
                  </Flex>
                </FormLabel>
                <Switch 
                  id="dark-mode" 
                  isChecked={user.darkMode}
                  onChange={handleColorModeChange}
                />
              </FormControl>
              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="notifications" mb="0" flex="1">
                  <Flex align="center">
                    <Icon as={FaBell} mr={2} />
                    Notifications
                  </Flex>
                </FormLabel>
                <Switch id="notifications" isChecked={notifications} onChange={(e) => setNotifications(e.target.checked)} />
              </FormControl>
            </Stack>

            <Divider my={6} />

            <Heading as="h3" size="md" mb={4}>Opt-Out Settings</Heading>
            <Stack spacing={6}>
              <Box>
                <FormControl>
                  <FormLabel>Opt-Out Phrase</FormLabel>
                  <InputGroup>
                    <Input
                      value={optOutPhrase}
                      onChange={(e) => setOptOutPhrase(e.target.value)}
                      placeholder="Enter opt-out phrase"
                      maxLength={50}
                    />
                    <InputRightElement width="4.5rem">
                      <Text fontSize="xs">{optOutPhrase.length}/50</Text>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
                <Text fontSize="sm" color="gray.500" mt={2}>
                  This phrase will be used to opt-out users who send it in response to your messages.
                </Text>
              </Box>

              <Box>
                <Heading as="h4" size="sm" mb={2}>Default Stop Triggers</Heading>
                <Text fontSize="sm" color="gray.500" mb={2}>
                  These stop triggers are always active and cannot be modified:
                </Text>
                <Flex flexWrap="wrap" gap={2}>
                  {defaultStopTriggers.map((trigger, index) => (
                    <Button key={index} size="sm" variant="outline" isDisabled>
                      {trigger}
                    </Button>
                  ))}
                </Flex>
              </Box>

              <Box>
                <Heading as="h4" size="sm" mb={2}>Custom Stop Triggers</Heading>
                <Flex mb={2}>
                  <Input
                    value={newStopTrigger}
                    onChange={(e) => setNewStopTrigger(e.target.value)}
                    placeholder="Add custom stop trigger"
                    mr={2}
                  />
                  <Button onClick={handleAddStopTrigger} colorScheme="blue" leftIcon={<FaPlus />}>
                    Add
                  </Button>
                </Flex>
                {customStopTriggers.map((trigger, index) => (
                  <Flex key={index} p={2} bg={bgColor} borderRadius="md" justify="space-between" align="center" mb={2}>
                    <Text>{trigger}</Text>
                    <Icon
                      as={FaTimes}
                      color="red.500"
                      cursor="pointer"
                      onClick={() => handleRemoveStopTrigger(index)}
                    />
                  </Flex>
                ))}
              </Box>
            </Stack>
          </Box>
        </Flex>
      </VStack>
    </Container>
  );
};

export default Settings;