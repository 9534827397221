import { useToast } from '@chakra-ui/react';

export const handleFileChange = (setFile, setHeaders) => (selectedFile) => {
  setFile(selectedFile);

  const reader = new FileReader();
  reader.onload = (event) => {
    const text = event.target.result;
    const lines = text.split('\n');
    const headers = lines[0].split(',').map(header => header.trim().replace('\r', ''));
    setHeaders(headers);
  };
  reader.readAsText(selectedFile);
};