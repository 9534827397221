import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  VStack,
  Input,
  Button,
  Text,
  Flex,
  Center,
  Icon,
  Spinner,
  IconButton,
  useColorMode,
  Skeleton,
} from '@chakra-ui/react';
import { FiSend, FiX, FiUser, FiCheck, FiClock, FiCheckCircle, FiAlertCircle, FiLoader, FiHelpCircle } from 'react-icons/fi';
import { BiMessageSquareError } from 'react-icons/bi';
import { toast } from 'react-toastify';
import sseManager from '../utils/sseManager';

const MessageView = ({ contact, onClose, isInModal = false, onUpdateContact }) => {
  const [messageText, setMessageText] = useState('');
  const [error, setError] = useState('');
  const [sendingMessage, setSendingMessage] = useState(false);
  const [messages, setMessages] = useState(contact?.messages || []);
  const messagesContainerRef = useRef(null);
  const { colorMode } = useColorMode();
  const isDark = colorMode === 'dark';

  // Update the color scheme
  const bgColor = isDark ? 'gray.900' : 'gray.50';
  const headerBgColor = isDark ? 'gray.800' : 'white';
  const headerTextColor = isDark ? 'gray.100' : 'gray.700';
  const headerSubTextColor = isDark ? 'gray.400' : 'gray.500';
  const messageBgColor = isDark ? 'gray.800' : 'white';
  const inputBgColor = isDark ? 'gray.800' : 'white';
  const scrollbarTrackColor = isDark ? '#1A202C' : '#EDF2F7';
  const scrollbarThumbColor = isDark ? '#2D3748' : '#CBD5E0';
  const scrollbarThumbHoverColor = isDark ? '#4A5568' : '#A0AEC0';

  useEffect(() => {
    if (contact) {
      setMessageText('');
      setError('');
      // Use setTimeout to ensure the scroll happens after render
      setTimeout(scrollToBottom, 0);
    }
  }, [contact]);

  // Update this useEffect to scroll after messages are updated
  useEffect(() => {
    if (contact?.messages) {
      setMessages(contact.messages);
      // Use setTimeout to ensure the scroll happens after render
      setTimeout(scrollToBottom, 0);
    }
  }, [contact?.messages]);

  // Add SSE listener for workflow messages
  useEffect(() => {
    if (contact?._id) {
      const handleWorkflowMessage = (data) => {
        if (data.type === 'messageSent' && data.contactId === contact._id) {
          // Update messages state with the new message
          setMessages(prevMessages => [...prevMessages, data.message]);
          
          // Update contact's lastMessage if needed
          if (contact && (!contact.lastMessage || 
              new Date(data.message.timestamp) > new Date(contact.lastMessage.timestamp))) {
            const updatedContact = {
              ...contact,
              lastMessage: data.message,
              lastMessageAt: data.message.timestamp
            };
            onUpdateContact(updatedContact);
          }
        }
      };

      sseManager.addListener('messageSent', handleWorkflowMessage);
      return () => {
        sseManager.removeListener('messageSent', handleWorkflowMessage);
      };
    }
  }, [contact?._id, onUpdateContact]);

  // Add this useEffect for status updates
  useEffect(() => {
    if (contact?._id) {
      const handleMessageStatus = (data) => {
        if (data.type === 'messageStatus' && data.contactId === contact._id) {
          setMessages(prevMessages => 
            prevMessages.map(msg => 
              msg.messageId === data.messageId 
                ? { ...msg, status: data.status }
                : msg
            )
          );
        }
      };

      sseManager.addListener('messageStatus', handleMessageStatus);
      return () => {
        sseManager.removeListener('messageStatus', handleMessageStatus);
      };
    }
  }, [contact?._id]);

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      const element = messagesContainerRef.current;
      element.scrollTop = element.scrollHeight;
    }
  };

  const handleSendMessage = async () => {
    if (!messageText.trim()) return;
    setSendingMessage(true);

    const tempId = Date.now().toString();
    const newMessage = {
      _id: tempId,
      sender: contact.userNumber,
      receiver: contact.contactNumber,
      text: messageText,
      timestamp: new Date().toISOString(),
      pending: true,
    };

    // Clear input and scroll before sending
    setMessageText('');
    
    // Update contact cache with the new message
    const updatedContact = {
      ...contact,
      messages: [...(contact.messages || []), newMessage],
      lastMessage: newMessage,
      lastMessageAt: newMessage.timestamp
    };
    
    // Update UI optimistically
    onUpdateContact(updatedContact);
    scrollToBottom();

    try {
      const response = await fetch('/api/messages/send', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${
            localStorage.getItem('userInfo')
              ? JSON.parse(localStorage.getItem('userInfo')).token
              : ''
          }`,
        },
        body: JSON.stringify({
          sender: contact.userNumber,
          receiver: contact.contactNumber,
          text: messageText,
          messagingProfileId: contact.messagingProfileId,
        }),
      });

      const data = await response.json();

      if (response.ok && data.success) {
        const sentMessage = {
          ...newMessage,
          _id: data.messageId,
          pending: false,
        };
        
        // Update contact cache with the confirmed message
        const finalUpdatedContact = {
          ...contact,
          messages: [...(contact.messages || []).filter(m => m._id !== tempId), sentMessage],
          lastMessage: sentMessage,
          lastMessageAt: sentMessage.timestamp
        };
        
        onUpdateContact(finalUpdatedContact);
      } else {
        throw new Error(data.error || 'Failed to send message');
      }
    } catch (error) {
      console.error('Error sending message:', error);
      toast.error('Failed to send message. Please try again.');
      // Optionally remove the pending message here
    } finally {
      setSendingMessage(false);
    }
  };

  // Display message when no conversation is selected
  if (!contact) {
    return (
      <Center height="100%">
        <Text fontSize="xl" color="gray.500" textAlign="center">
          Please select a conversation to display messages.
        </Text>
      </Center>
    );
  }

  const displayName = (contact.firstName || contact.lastName
    ? `${contact.firstName || ''} ${contact.lastName || ''}`.trim()
    : contact.contactNumber).length > 25
    ? `${(contact.firstName || contact.lastName
        ? `${contact.firstName || ''} ${contact.lastName || ''}`.trim()
        : contact.contactNumber).slice(0, 25)}...`
    : (contact.firstName || contact.lastName
        ? `${contact.firstName || ''} ${contact.lastName || ''}`.trim()
        : contact.contactNumber);

  const StatusIcon = ({ status, pending }) => {
    if (pending) return <Icon as={FiClock} color="gray.200" ml={1} boxSize={3} />;
    
    switch (status) {
      case 'delivered':
        return <Icon as={FiCheckCircle} color="green.300" ml={1} boxSize={3} title="Delivered" />;
      case 'sent':
        return <Icon as={FiCheck} color="blue.300" ml={1} boxSize={3} title="Sent" />;
      case 'queued':
        return <Icon as={FiClock} color="gray.300" ml={1} boxSize={3} title="Queued" />;
      case 'sending':
        return (
          <Icon 
            as={FiLoader} 
            color="blue.300" 
            ml={1} 
            boxSize={3} 
            className="spinning" 
            title="Sending"
          />
        );
      case 'sending_failed':
        return <Icon as={FiAlertCircle} color="red.300" ml={1} boxSize={3} title="Sending Failed" />;
      case 'delivery_failed':
        return <Icon as={FiAlertCircle} color="red.300" ml={1} boxSize={3} title="Delivery Failed" />;
      case 'delivery_unconfirmed':
        return <Icon as={FiHelpCircle} color="yellow.300" ml={1} boxSize={3} title="Delivery Unconfirmed" />;
      default:
        return null;
    }
  };

  return (
    <Flex
      flexDirection="column"
      height={isInModal ? '70vh' : '100%'}
      bg={bgColor}
      borderRadius="lg"
      overflow="hidden"
      boxShadow="lg"
    >
      <Box
        p={4}
        borderBottomWidth="1px"
        borderColor={isDark ? 'gray.700' : 'gray.200'}
        bg={headerBgColor}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        boxShadow="sm"
      >
        <Flex alignItems="center" gap={3}>
          <Center
            bg={isDark ? 'gray.700' : 'gray.100'}
            w={10}
            h={10}
            borderRadius="full"
            color={isDark ? 'gray.300' : 'gray.600'}
          >
            <Icon as={FiUser} boxSize={5} />
          </Center>
          <Box>
            <Text 
              fontSize="lg" 
              fontWeight="600" 
              color={headerTextColor} 
              noOfLines={1}
            >
              {displayName}
            </Text>
            <Text 
              fontSize="sm" 
              color={headerSubTextColor}
              noOfLines={1}
            >
              {contact.contactNumber}
            </Text>
          </Box>
        </Flex>
        {isInModal && (
          <IconButton
            icon={<FiX />}
            onClick={onClose}
            aria-label="Close Message View"
            variant="ghost"
            borderRadius="full"
            _hover={{
              bg: isDark ? 'gray.700' : 'gray.100'
            }}
          />
        )}
      </Box>
      <VStack
        flex={1}
        overflowY="auto"
        p={4}
        spacing={4}
        alignItems="stretch"
        bg={messageBgColor}
        ref={messagesContainerRef}
        sx={{
          '&::-webkit-scrollbar': {
            width: '6px',
          },
          '&::-webkit-scrollbar-track': {
            background: scrollbarTrackColor,
          },
          '&::-webkit-scrollbar-thumb': {
            background: scrollbarThumbColor,
            borderRadius: '6px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: scrollbarThumbHoverColor,
          },
        }}
      >
        {error ? (
          <Center flex={1} flexDirection="column">
            <Icon as={BiMessageSquareError} boxSize={12} color="red.400" />
            <Text fontSize="lg" color="red.500" mt={2}>
              {error}
            </Text>
          </Center>
        ) : messages.length > 0 ? (
          messages.map((msg, index) => (
            <Box
              key={msg._id || index}
              alignSelf={
                msg.sender === contact.userNumber ? 'flex-end' : 'flex-start'
              }
              bg={
                msg.sender === contact.userNumber ? 'blue.500' : isDark ? 'gray.700' : 'gray.100'
              }
              color={
                msg.sender === contact.userNumber ? 'white' : isDark ? 'gray.100' : 'gray.800'
              }
              p={3}
              borderRadius="2xl"
              maxWidth="80%"
              boxShadow="sm"
              opacity={msg.pending ? 0.7 : 1}
              transition="all 0.2s"
              _hover={{
                transform: 'translateY(-1px)',
                boxShadow: 'md',
              }}
            >
              <Text>{msg.text}</Text>
              <Flex 
                fontSize="xs" 
                color={msg.sender === contact.userNumber ? 'gray.200' : 'gray.500'} 
                mt={1}
                alignItems="center"
              >
                <Text>{new Date(msg.timestamp).toLocaleString()}</Text>
                {msg.sender === contact.userNumber && msg.direction === 'outbound' && (
                  <StatusIcon status={msg.status} pending={msg.pending} />
                )}
              </Flex>
            </Box>
          ))
        ) : (
          <Center flex={1} flexDirection="column">
            <Icon as={BiMessageSquareError} boxSize={12} color="gray.400" />
            <Text fontSize="lg" color="gray.500" mt={2}>
              No messages to display
            </Text>
          </Center>
        )}
      </VStack>
      <Box p={4} borderTopWidth="1px" bg={headerBgColor} boxShadow="sm">
        <Flex>
          <Input
            value={messageText}
            onChange={(e) => setMessageText(e.target.value)}
            placeholder="Type your message..."
            mr={2}
            bg={inputBgColor}
            borderRadius="full"
            borderColor={isDark ? 'gray.600' : 'gray.200'}
            _focus={{
              borderColor: 'blue.400',
              boxShadow: '0 0 0 1px var(--chakra-colors-blue-400)',
            }}
            _hover={{
              borderColor: isDark ? 'gray.500' : 'gray.300',
            }}
            _placeholder={{
              color: isDark ? 'gray.500' : 'gray.400',
            }}
            isDisabled={!contact || sendingMessage}
          />
          <Button
            onClick={handleSendMessage}
            colorScheme="blue"
            rightIcon={<FiSend />}
            borderRadius="full"
            px={6}
            _hover={{ 
              transform: 'translateY(-1px)',
              boxShadow: 'md',
            }}
            transition="all 0.2s"
            isDisabled={!contact || sendingMessage}
            isLoading={sendingMessage}
          >
            Send
          </Button>
        </Flex>
      </Box>
    </Flex>
  );
};

export default MessageView;
