import React, { useEffect, useRef } from 'react';
import {
  Box,
  Heading,
  useColorModeValue,
} from '@chakra-ui/react';

const Dashboard = () => {
  const bgColor = useColorModeValue('white', 'gray.800');
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      const video = videoRef.current;
      video.addEventListener('loadedmetadata', () => {
        const randomTime = Math.random() * video.duration;
        video.currentTime = randomTime;
      });
    }
  }, []);

  return (
    <Box p={6} bg={useColorModeValue('gray.100', 'gray.900')}>


      <Box bg={bgColor} p={6} borderRadius="lg" boxShadow="md" mb={6}>
        <Heading as="h2" size="md" mb={4}>
          Entertainment Station!
        </Heading>
        <Box maxWidth="100%" overflow="hidden" borderRadius="md">
          <video 
            ref={videoRef}
            width="100%" 
            autoPlay 
            muted 
            loop 
            playsInline
            controls
          >
            <source src="/stim.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
