import React from 'react';
import { useLocation } from 'react-router-dom';
import { Flex, Heading } from '@chakra-ui/react';

const getPageTitle = (path) => {
  switch (path) {
    case '/dashboard':
      return 'Dashboard';
    case '/conversations':
      return 'Conversations';
    case '/contacts':
      return 'Contacts';
    case '/workflows':
      return 'Workflows';
    case '/workflow-editor':
      return 'Workflow Editor';
    case '/opportunities':
      return 'Opportunities';
    case '/tags':
      return 'Tags';
    case '/uploads':
      return 'Uploads';
    case '/phone-numbers':
      return 'Phone Numbers';
    case '/settings':
      return 'Settings';
    default:
      return 'Paladin CRM';
  }
};

const Navbar = () => {
  const location = useLocation();
  const pageTitle = getPageTitle(location.pathname);

  return (
    <Flex bg="gray.800" p={4} color="white" align="center" boxShadow="md">
      <Heading as="h1" size="lg">{pageTitle}</Heading>
    </Flex>
  );
};

export default Navbar;